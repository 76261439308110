<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center justify-between">
        <div class="relative px-4">
          <h3
            class="text-lg text-blueGray-700"
          >
          <i class="pi text-sm pi-database"></i> Commissions
          </h3>
        </div>
        <div class="flex mb-3">
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            :disabled="loading"
            @click="toggleModal('filter', true)"
          >
          <i class="pi pi-filter text-xs" style="font-size: 10px;"></i> Filter
          </button>
          
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            @click="() => {get_csv=true;fetchNewCommissionPage()}"
            :disabled="!has('transaction.view') || loading || loaders().fetchTransactions" 
          >
          <i class="pi pi-download text-xs" style="font-size: 10px;"></i> Download
          </button>
        </div>
      </div>
    </div>


    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Date
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Amount
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Type
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Total Sales
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Details
            </th>
          </tr>
        </thead>
        <tbody v-if="has('commissions.view')">
          <template v-if="!loaders().getCommission">
            <tr 
              v-for="(commission, i) in commissions().data" 
              :key="i"
            >
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">{{ moment(commission.created_at).format("DD MMM YY hh:mm") }}</span>
                </div>
              </td>
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                <span>
                  {{ commission.status }} 
                  <small v-if="['PAID', 'PENDING', 'WITHHELD', 'DENIED'].includes(commission.status)"
                  :class="[
                    commission.status === 'PAID' ? 'text-emerald-600' : '',
                    commission.status === 'DENIED' ? 'text-orange-500' : '',
                    commission.status === 'PENDING' ? 'text-blueGray-600' : '',
                  ]"
                  >({{ {"PAID": "CR", "DENIED": "DR", "PENDING": "NONE"}[commission.status] }})</small>
                </span>
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <i :class="`fas mr-4 ${commission.status === 'PAID' ? 'fa-arrow-up text-emerald-500' : commission.status === 'PENDING' ? 'fa-arrow-right text-gray-500' : 'fa-arrow-down text-red-500'}`"></i>
                {{ Math.round(commission.amount * 10 ) / 10 }} NGN
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <!-- <i class="fas fa-circle text-orange-500 mr-2"></i> --> {{ commission.sales_type }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">{{ commission.sales }}</span>
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
                @click="() => veiwCommission(commission)"
              >
                <!-- <trans-dropdown :data="{}" /> -->
                <span class="text-blueGray-500 py-1 px-3"><i class="fas fa-ellipsis-v"></i></span>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="7" rowspan="7">
                <div class="flex items-center justify-center w-full py-12 h-30">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <permission-err title="commissions" v-if="!has('commissions.view')"></permission-err>
      <empty-list v-if="(!commissions().data || commissions().data.length === 0) && !loaders().getCommission && has('commissions.view')"></empty-list>
    </div>
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <circular-pagination :current_page="commissions().current_page" :last_page="commissions().last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
      </div>
    </div>


    <div v-if="modals.commission" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Commission
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('commission', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <table>
              <tbody>
                <tr
                v-for="([k, v], i) in Object.entries(commission)" :key="i"
                class="border-b-1"
                >
                  <template v-if="true">
                    <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left"
                    >{{ k.split("_").join(" ") }}</td> 
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-left flex items-center">
                      <template v-if="(typeof v === 'object') && v !== null">
                        <table>
                          <tbody>
                            <tr
                            v-for="([a, b], j) in Object.entries(v)" :key="j"
                            >
                              <td>{{ a.split("_").join(" ") }}</td>
                              <td>{{ b }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </template>
                      <template v-else>
                        {{ v }}
                      </template>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('commission', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.commission" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>

    <filter-modal 
      :selects="{
        category: commissionList, 
      }"
      :dates="{
        from: 'Start Date',
        to: 'End Date'
      }"
      :searchable="true"
      :sizable="true"
      :visible="modals.filter"
      @close="modals.filter=false" 
      title="commission"
      @filter="filter"
      :loading="loading || loaders().getCommission"
    ></filter-modal>

  </div>
</template>
<script>
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import { commissionList } from '../../utils/constant.util'
import PermissionErr from "@/components/Cards/PermissionErr.vue";
import EmptyList from "@/components/Cards/EmptyList.vue";
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import FilterModal from "@/components/Modals/FilterModal.vue";
import moment from "moment"
const toaster = createToaster({ /* options */ });

export default {
  data() {
    return {
      pageSize: 10,
      params: {},
      loading: false,
      get_csv: false,
      pagination: {},
      commissionList,
      modals: {},
      commission: {}
    };
  },
  components: {
    // TransDropdown,
    CircularPagination,
    PermissionErr,
    FilterModal,
    EmptyList
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    ...mapActions(["getCommission"]),
    ...mapGetters(["commissions", "loaders"]),
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewCommissionPage();
    },
    fetchNewCommissionPage(p={}) {
      const params = {
        ...this.pagination,
        get_csv: this.get_csv,
        ...this.params,
        ...p
      }
      const self = this
      this.loading = true

      this.getCommission(params).then( response => {
        if ( self.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','commissions.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          toaster.success("Report downloaded Successfully");
        }
      }).finally(() => {
        self.get_csv = false
        self.loading = false
      })
    },
    filter(params) {
      this.params = params;
      this.fetchNewTransactionPage(params);
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    veiwCommission(commission) {
      this.commission = {
        identification: commission.identification,
        sales_type: commission.sales_type, 
        sales: commission.sales,
        amount: commission.amount,
        ...(commission.business || {})
      }; 
      this.toggleModal('commission', true)
    }
  },
  mounted() {
    const params = {}
    const {search} = this.$route.query;
    if ( search ) {
      params.search = search
    }
    this.getCommission(params)
  },
  created() {
    this.moment = moment
  }
};
</script>
