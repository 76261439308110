import AccountMutation from "./account.mutation";
import LoaderMutation from "./loader.mutation";
import QuestionMutation from "./question.mutation";
import NotificationMutation from "./notification.mutation";
import TransactionMutation from "./transaction.mutation";
import UserMutation from "./user.mutation";
import PlanMutation from "./plan.mutation";
import PredictionMutation from "./prediction.mutation";
import SettingsMutation from "./settings.mutation";
import bankAccountMutation from "./bankAccount.mutation";
import WithdrawMutation from "./withdraw.mutation";
import ToastMutation from "./toast.mutation";
import CoinMutation from "./coin.mutation";
import MetricsMutation from "./metrics.mutation";
import CurrencyMutation from "./currency.mutation";
import RoleMutation from "./role.mutation";
import BusinessMutation from "./business.mutation";
import ItemMutation from "./item.mutation";
import VasMutation from "./vas.mutation";
import AppMutation from "./app.mutation";
import SubscriptionMutation from "./subscription.mutation";

const mutations = {
    ...SettingsMutation,
    ...TransactionMutation,
    ...UserMutation,
    ...PlanMutation,
    ...LoaderMutation,
    ...NotificationMutation,
    ...AccountMutation,
    ...QuestionMutation,
    ...PredictionMutation,
    ...bankAccountMutation,
    ...WithdrawMutation,
    ...ToastMutation,
    ...CoinMutation,
    ...MetricsMutation,
    ...CurrencyMutation,
    ...RoleMutation,
    ...BusinessMutation,
    ...ItemMutation,
    ...VasMutation,
    ...AppMutation,
    ...SubscriptionMutation
};

export default mutations;