<template>
  <div>
    <admin-balance-stats />
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
          <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
            <li class="-mb-px mr-2 last:mr-0" v-if="has('airtime.vend.create')">
              <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer" v-on:click="toggleTabs('sme-airtime-topup')" v-bind:class="{'text-emerald-600 bg-white': activeVas !== 'sme-airtime-topup', 'text-white bg-orange-500': activeVas === 'sme-airtime-topup'}">
                <i class="pi pi-phone"></i> Airtime
              </a>
            </li>
            <li class="-mb-px mr-2 last:mr-0" v-if="has('data.service.view')">
              <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer" v-on:click="toggleTabs('sme-data-topup')" v-bind:class="{'text-emerald-600 bg-white': activeVas !== 'sme-data-topup', 'text-white bg-orange-500': activeVas === 'sme-data-topup'}">
                <i class="pi pi-mobile"></i> SME Data
              </a>
            </li>
            <li class="-mb-px mr-2 last:mr-0" v-if="has('power.service.view')">
              <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer" v-on:click="toggleTabs('sme-power')" v-bind:class="{'text-emerald-600 bg-white': activeVas !== 'sme-power', 'text-white bg-orange-500': activeVas === 'sme-power'}">
                <i class="pi pi-bolt"></i> Power/Electricity
              </a>
            </li>
            <li class="-mb-px mr-2 last:mr-0" v-if="has('cable.service.view')">
              <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer" v-on:click="toggleTabs('sme-cable-tv')" v-bind:class="{'text-emerald-600 bg-white': activeVas !== 'sme-cable-tv', 'text-white bg-orange-500': activeVas === 'sme-cable-tv'}">
                <i class="pi pi-desktop"></i> TV Cable
              </a>
            </li>
          </ul>
        </div>
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div class="px-4 py-5 flex-auto">
            <div class="tab-content tab-space">
              <div class="flex flex-wrap block">
                <transition name="slide-fade">
                  <div class="w-full px-4" :class="{'lg:w-8/12': vas}">
                    <card-transaction :category="activeVas" :canCreate="true" @create="handleCreateTransaction"></card-transaction>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div class="w-full lg:w-4/12 px-4" v-if="vas">
                    <card-business-vas :selectedVas="vas" @close="handleCloseVas"></card-business-vas>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardTransaction from "@/components/Cards/CardTransaction.vue";
import CardBusinessVas from "@/components/Cards/CardBusinessVas.vue";
import AdminBalanceStats from "@/components/Stats/AdminBalanceStats.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CardTransaction,
    CardBusinessVas,
    AdminBalanceStats
  },
  data() {
    return {
      activeVas: 'sme-airtime-topup',
      param: {},
      vas: null
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions([]),
    ...mapGetters([]),
    toggleTabs: function(vas){
      this.activeVas = vas
      this.handleCloseVas()
    },
    handleCloseVas(){
      this.vas = null
    },
    handleCreateTransaction(){
      this.vas = this.activeVas
    }
  }
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
