<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                <i class="fas fa-fingerprint"></i> Verify Account
              </h6>
              <p v-if="query.message" class="mb-4">{{ query.message }}</p>
              <p v-else class="mb-4">Enter the OTP code sent to<strong class="ms-1 text-success">your phone or email</strong></p>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="block text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  <i class="pi pi-key"></i> OTP Code
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  placeholder="OTP Code"
                  v-model="v$.auth.code.$model"
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.auth.code.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>

              <div class="text-center mt-6">
                <app-button 
                  :classes="'bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 flex items-center justify-center'" 
                  @click="register" 
                  :title="'Verify'" 
                  :loading="loaders().verifyUser || loaders().authUser" 
                  :disabled="loaders().verifyUser || loaders().authUser || v$.auth.$invalid" 
                >
                <i class="pi pi-verified"></i>
                </app-button>
              </div>

              <div class="mt-4 text-red-500">
                <span v-for="(err, i) in Object.values(errors)" :key="i">
                  {{ err }}
                </span>
              </div>
            </form>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import AppButton from '@/components/Form/AppButton.vue';
import { required, minLength } from '@vuelidate/validators';
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    AppButton
  },
  data() {
    return {
      auth: {},
      errors: {},
      query: {}
    };
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      auth: {
        code: { required, min: minLength(6) }
      },
    }
  },
  methods: {
    ...mapActions([
      "verifyUser",
      "authUserWithOTP"
    ]),
    ...mapGetters([
      "loaders"
    ]),
    register() {
      const code = this.auth.code.includes('-') ? this.auth.code : this.auth.code.slice(0, 3) + "-" + this.auth.code.slice(3)
      const { email, page, referrer, nin, bvn } = this.query;
      const data = {
        code,
        email,
        referrer, 
        nin, 
        bvn
      };

      let req;
      switch (page) {
        case 'validate':
          req = this.verifyUser(data);
          break;
        // case 'identity-registration':
        //   req = this.identityRegistrationCreate(data);
        //   break;
        // case 'identity-auth':
        //   req = this.identityAuthCreate(data);
        //   break;
        case 'otp-login':
          req = this.authUserWithOTP(data)
          break;
        default:
          req = this.validateCode(data);
          break;
      }
      
      const self = this;
      req.then( function () {
        if (page === "identity-auth" || page === "identity-registration" || page === "otp-login" ) {
          self.$router.push('/admin');
        } else {
          self.$router.push('/auth/login');
        }
      }).catch( function (err) {
        const { message, data } = err || {}
        self.errors = { ...data, message }
      }).finally(() => {})
    }
  },
  mounted() {
    this.query = this.$route.query || this.$route.params || {};
  }
};
</script>
