<template>
    <div class="" v-if="hasSubscribed">
        <slot v-if="isPermitted">
        </slot>
        <div v-else-if="isGracePreriod" class="relative">
            <div class="absolute top-0 right-0 px-2 bg-red-200 text-xs z-40" style="font-size: 8px;"><i class="pi pi-exclamation-circle" style="font-size: 5px;"></i></div>
            <div class="relative opacity-25 cursor-not-allowed z-30 pointer-events-none" @click="handleExpiredClick">
                <slot></slot>
            </div>
        </div>
        <div v-else class="relative">
            <div class="absolute top-0 right-0 px-2 bg-red-200 text-xs z-40" style="font-size: 8px;"><i class="pi pi-bookmark" style="font-size: 5px;"></i></div>
            <div class="relative opacity-25 cursor-not-allowed z-40" @click="handleExpiredClick">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from "moment"
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
    name: 'ProductPackage',
    props: {
        package: {
            default: "light",
            type: String,
        },
        product: {
            default: "light",
            type: String,
        }
    },
    created() {
        this.moment = moment
    },
    computed: {
        ...mapGetters({
            businessSubscription: "subscription"
        }),
        isPermitted() {
            if (!this.product && !this.package) return true
            if (this.product === "" && this.package === "") return true

            const { package: pack, product, expires_at } = this.getSubscription();
            
            if (this.product && !product || (product && product.toLowerCase() !== this.product.toLowerCase())) {
                return false
            }

            if (this.package && !pack || (pack && pack.toLowerCase() !== this.package.toLowerCase())) {
                return false
            }

            if ((this.product || this.package) && moment(expires_at).isBefore(moment(new Date))) {
                return false
            }

            return true
        },
        hasSubscribed() {
            // const subs = this.businessSubscription;
            return true;// (subs || []).length > 0;
        },
        isGracePreriod() {
            const { expires_at } = this.getSubscription();
            if (!expires_at) return false
            return moment(expires_at).add(3, 'days').isAfter(new Date())
        }
    },
    data() {
        return {
        };
    },
    methods: {
        getSubscription() {
            if (!this.product && !this.package) {
                return {}
            }
            return (this.businessSubscription || []).find(
                b => b.product === this.product || b.package.toLowerCase() === this.package.toLowerCase()
            ) || {}
        },
        handleExpiredClick() {
            toaster.error("Your subscription has expired.")
        }
    }
}
</script>