import { GetSubscription } from "@/services/subscription";

export const getSubscription = (context) => {
  context.commit("updateLoader", { fetchSubscription: true });
  return new Promise((resolve, reject) => {
    GetSubscription()
      .then(res => {
        context.commit("fetchSubscription", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { fetchSubscription: false });
      });
    });
};

const SubscriptionAction = {
  getSubscription
};

export default SubscriptionAction;
