<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-emerald-500 flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <router-link
        class="md:block text-left md:pb-2 text-blueGray-100 mr-0 inline-block whitespace-nowrap text-sm font-bold p-4 px-0"
        to="/"
      >
        <img :src="LightLogo" alt="Redge" width="50px" class="logo-img" />
      </router-link>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <notification-dropdown />
        </li>
        <li class="inline-block relative">
          <user-dropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded bg-emerald-500"
        v-bind:class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-blueGray-100 mr-0 inline-block whitespace-nowrap text-sm font-bold p-4 px-0"
                to="/"
              >
                <img :src="LightLogo" alt="Redge" width="50px" class="logo-img" />
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- Form -->
        <form class="mt-6 mb-4 md:hidden">
          <div class="mb-3 pt-0">
            <input
              type="text"
              placeholder="Search"
              class="border-0 px-3 py-2 h-12 border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
            />
          </div>
        </form>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <template v-for="(menu, i) in menus">
            <li 
              class="items-center" 
              :key="i" 
              v-if="hasAny(menu.permissions) && isAny(menu.roles)"
            >
              <product-package :product="menu.product" :package="menu.package">
                <router-link
                  :to="menu.url"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="() => { navigate; $emit('menuClick', menu) }"
                    class="text-sm py-3 block"
                    :class="[
                      isActive
                        ? 'bg-white rounded px-4 hover:bg-emerald-400 text-emerald-500'
                        : 'text-blueGray-100 hover:text-blueGray-300',
                    ]"
                  >
                    <i
                      class="pi mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-100', menu.icon]"
                      style="width: 15px;"
                    ></i>
                    {{ menu.title }}
                  </a>
                </router-link>
              </product-package>
            </li>
          </template>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
          <li class="items-center" v-if="has('logout.ability')">
            <router-link
              class="text-blueGray-100 hover:text-blueGray-500 text-xs py-3 font-bold block cursor-pointer"
              to="#"
              @click="logoutUser"
            >
              <i class="fas fa-fingerprint text-blueGray-300 mr-2 text-sm"></i>
              Logout
            </router-link>
          </li>

          <!-- <li class="items-center">
            <router-link
              class="text-blueGray-100 hover:text-blueGray-500 text-xs py-3 font-bold block"
              to="/auth/register"
            >
              <i
                class="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"
              ></i>
              Register Admin
            </router-link>
          </li> -->
        </ul>


        <!-- <hr class="my-4 md:min-w-full" />
        <h6
          class="md:min-w-full text-blueGray-500 text-xs font-bold block pt-1 pb-4 no-underline"
        >
          Documentation
        </h6>
        <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">

          <li class="inline-flex">
            <a
              href="https://developer.redge.ng"
              target="_blank"
              class="text-blueGray-100 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
            >
              <i class="fab fa-js-square mr-2 text-blueGray-300 text-base"></i>
              Javascript SDK
            </a>
          </li>

          <li class="inline-flex">
            <a
              href="https://developer.redge.ng"
              target="_blank"
              class="text-blueGray-100 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
            >
              <i class="fas fa-link mr-2 text-blueGray-300 text-base"></i>
              Developer
            </a>
          </li>

        </ul> -->

      </div>
    </div>
  </nav>
</template>


<script>
import NotificationDropdown from "@/components/Dropdowns/NotificationDropdown.vue";
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import { mapActions } from "vuex";
import LightLogo from "@/assets/img/redge-light.png"
import ProductPackage from "@/components/Cards/ProductPackage.vue";

export default {
  components: {
    NotificationDropdown,
    UserDropdown,
    ProductPackage
  },
  data() {
    return {
      collapseShow: "hidden",
      LightLogo,
      menus: [
        {
          url: '/admin/dashboard',
          icon: 'pi-home',
          title: 'Dashboard',
          permissions: [],
          roles: [],
          product: "",
          package: ""
        },
        {
          url: '/admin/transactions',
          icon: 'pi-credit-card',
          title: 'Transactions',
          permissions: ['transaction.all.view'],
          roles: [],
          product: "",
          package: ""
        },
        {
          url: '/admin/vas',
          icon: 'pi-mobile',
          title: 'Business Vas',
          permissions: ['airtime.vend.create', 'data.vend.create', 'power.vend.create', 'cable.vend.create'],
          roles: [],
          product: "",
          package: "Standard"
        },
        {
          url: '/admin/products',
          icon: 'pi pi-barcode',
          title: 'Products',
          permissions: ['plans.index'],
          roles: [],
          product: "",
          package: ""
        },
        {
          url: '/admin/plans',
          icon: 'pi-credit-card',
          title: 'Loans & Savings',
          permissions: ['plans.index'],
          roles: [],
          product: "",
          package: ""
        },
        {
          url: '/admin/wallet',
          icon: 'pi-wallet',
          title: 'Wallet',
          permissions: [],
          roles: [],
          product: "",
          package: ""
        },
        {
          url: '/admin/users',
          icon: 'pi-users',
          title: 'Users',
          permissions: ['user.all.view'],
          roles: [],
          product: "",
          package: ""
        },
        {
          url: '/admin/insight',
          icon: 'pi-building-columns',
          title: 'Manage',
          permissions: [],
          roles: ['business_manager'],
          product: "",
          package: ""
        },
        {
          url: '/admin/settings',
          icon: 'pi-cog',
          title: 'Settings',
          permissions: [],
          roles: [],
          product: "",
          package: ""
        }
      ]
    };
  },
  methods: {
    ...mapActions([
      "logout"
    ]),
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
    logoutUser: function() {
      this.logout().then( res => {
        console.log(res);
        const self = this;
        setTimeout( function(){
          self.$router.push('/auth');
        }, 900);
      }).catch( err => {
        console.log(err)
      })
    },
  },
  mounted(){
    this.$emit('menuClick', this.menus[0])
  }
};
</script>

<style scoped>
.logo-img {
  width: 50px;
}
</style>