<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xs font-bold">Product Settings</h6>
        <button
          class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="(e) => $emit('close', e)"
        >
          <i class="fa fa-times"></i> 
        </button>
      </div>
    </div>
    <div class="flex-auto px-1 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Product Information
        </h6>
        <div class="w-full px-1">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            Name
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              v-model="v$.product.name.$model"
              :disabled="loaders().updateItem || loaders().addItem"
            />
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.product.name.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            Price
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              v-model="v$.product.price.$model"
              :disabled="loaders().updateItem || loaders().addItem"
            />
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.product.price.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            Available Unit
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              v-model="v$.product.quantity.$model"
              :disabled="loaders().updateItem || loaders().addItem"
            />
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.product.quantity.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            Description
            </label>
            <textarea
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              v-model="v$.product.description.$model"
              :disabled="loaders().updateItem || loaders().addItem"
            ></textarea>
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.product.description.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            Discount
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              v-model="v$.product.discount.$model"
              :disabled="loaders().updateItem || loaders().addItem"
            />
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.product.discount.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            Plan
            </label>
            <select
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              v-model="v$.product.plan_id.$model"
              :disabled="loaders().updateItem || loaders().addItem"
            >
              <option v-for="(plan, i) in plans()" :key="i" :value="plan.id" :selected="plan.id === product.plan_id">{{ plan.name }}</option>
            </select>
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.product.plan_id.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
        <div v-if="product.name && product.price">
          <hr class="mt-3 border-b-1 border-blueGray-300"/>
          <div class="relative w-full my-3 flex">
            <div class="w-full lg:w-6/12 px-1">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Product thumbnail
                </label>
                <input
                  type="file"
                  :disabled="loaders().addItem || loaders().updateItem"
                  class="border-0 px-2 py-1 h-8 bg-white text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  @change="uploadThumbNail"
                />
            </div>
            <div class="w-full lg:w-6/12 px-4 flex items-center swiper">
              <div class="relative w-full mb-3 swiper-wrapper">
                <template  v-if="product.thumbnails">
                <div v-for="(thumbnail, i) in item.thumbnails" :key="i"  class="flex items-center swiper-slide">
                  <img :src="thumbnail" class="preview-image" />
                </div>
                </template>
                <div v-else class="text-xs mt-2"><i>Supply a logo not more than 100kb and 700 x 250 </i></div>
                <div v-if="uploading.thumbnail">
                  <i>updatiing logo...</i>
                </div>
              </div>
               <!-- If we need pagination -->
               <div class="swiper-pagination"></div>
              <!-- If we need navigation buttons -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
              <!-- If we need scrollbar -->
              <div class="swiper-scrollbar"></div>
            </div>
          </div>
          <div class="relative w-full my-3 flex">
            <div class="w-full lg:w-6/12 px-1">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Banner
                </label>
                <input
                  type="file"
                  :disabled="loaders().addItem || loaders().updateItem"
                  class="border-0 px-2 py-1 h-8 bg-white text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  @change="uploadBanner" 
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.product.banner.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class="w-full lg:w-6/12 px-4 flex items-center">
              <div class="relative w-full mb-3">
                <div v-if="product.banner" class="flex items-center">
                  <img :src="product.banner" class="preview-image"/>
                </div>
                <div v-else class="text-xs mt-2"><i>Supply a banner not more than 500kb and 1920 x 1280 </i></div>
                <div v-if="uploading.banner">
                  <i>updatiing banner...</i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-6 border-b-1 border-blueGray-300" />
        <div class="relative w-full mb-3 px-1 mt-8">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="processProduct" 
            :disabled="loaders().updateItem || loaders().addItem"
          >
            Save
          </button>
        </div>

      </form>
    </div>

    <div v-if="showDebitModal" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Debit Loan
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="showDebitModal=false"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <div class="w-full px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Debit Amount
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.debit_amount.$model"
                  :disabled="loaders().repayLoan"
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.debit_amount.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="showDebitModal=false"
            >
              Close
            </button>
            <button class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 flex rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            type="button" 
            :disabled="loaders().repayLoan"
            @click="processDebitLoan">
              Debit
              <span v-if="loaders().repayLoan" class="flex loader loader-sm"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDebitModal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, decimal } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import { getImageDimensions } from "@/utils"
import { UploadBanner, UploadThumbnail } from '../../services/item';
// import { Loader } from 'google-maps';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
const toaster = createToaster({ /* options */ });

export default {
  props: [ "selectedProduct" ],
  components: {
  },
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    selectedProduct: function(product) {
      this.product = product
    }
  },
  computed: {
    item: function() {
      const item = this.product
      return item
    }
  },
  data() {
    return {
      product: this.selectedProduct || {},
      showDebitModal: false,
      uploading: {},
    }
  },
  validations() {
    return {
      product: {
        price: { required, decimal },
        name: { required },
        quantity: { required },
        thumbnail: { required },
        banner: { required },
        discount: { decimal },
        description: {},
        plan_id: {}
      },
    }
  },
  methods: {
    ...mapGetters(["loaders", "plans"]),
    ...mapActions([ "updateItem", "addItem", "getAllPlans" ]),    
    async uploadBanner(e) {
      if (!this.product.id) {
        await this.processProduct()
      }
      const file = e.target.files[0];
      const { width, height } = await getImageDimensions(file)
      if ( width > 1920 || height > 1280 ) {
        toaster.error("Upload a logo of size not more than 1920 x 1280")
        return
      }
      if ( file.size/1024 > 500 ) {
        toaster.error("Upload a logo of size not more than 500kb")
        return
      }
      
      const formData = new FormData();
      formData.append("file", file);
      this.uploading.banner = true;
      const res = await UploadBanner(formData, this.product.id);
      this.uploading.banner = false;
      this.product.banner = res.data.data;
    },
    async uploadThumbNail(e) {
      if (!this.product.id) {
        await this.processProduct()
      }
      const file = e.target.files[0];
      const { width, height } = await getImageDimensions(file)
      if ( width > 700 || height > 300 ) {
        toaster.error("Upload a thumbnail of size not more than 700 x 250")
        return
      }
      if ( file.size/1024 > 100 ) {
        toaster.error("Upload a thumbnail of size not more than 100kb")
        return
      }
      
      const formData = new FormData();
      formData.append("file", file);
      this.uploading.thumbnail = true;
      const res = await UploadThumbnail(formData, this.product.id);
      console.log(res.data.data)
      this.uploading.thumbnail = false;
      this.product.thumbnails = [
        ...(this.product.thumbnails || []),
        res.data.data
      ];
      
    },
    async processProduct() {
      const query = this.product.id ? this.updateItem : this.addItem
      const payload = this.product
      if (payload.thumbnails && typeof this.product.thumbnails === "string") {
        payload.thumbnails = JSON.parse(payload.thumbnails)
      }
      try {
        const { id } = await query(payload)
        if (id) {
          this.product.id = id;
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
  mounted() {
    new Swiper('.swiper', {
      modules: [Navigation, Pagination],
      loop: true,
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    });
    this.getAllPlans();
  }
}
</script>

<style scoped>
.swiper-button-next, .swiper-button-prev {
  color: #10b981;
}
.swiper-pagination-bullet-active {
    background: #10b981 !important
}
</style>