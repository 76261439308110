<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  async mounted() {
    await this.fetchTenant()
    await this.getSubscription()
  },
  methods: {
    ...mapActions(["fetchTenant", "getSubscription"]),
  }
}
</script>

<style>
html, body {
    /* font-family: "Open Sans", sans-serif; */
    font-family: "inter", sans-serif;
    font-weight: 100;
    font-style: normal;
    font-variation-settings: "wdth" 100;
}
</style>