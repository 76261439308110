import Swal from 'sweetalert2'

export const closeModal = () => {
  window.location.href = `${window.location.href.split("#")[0]}` + "#"
}

export const confirmAction = ({confirmText, confirmTextHelper, successText, cancelText}, func, errfunc = null) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150",
      cancelButton: "bg-red-500 text-white active:bg-red-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
    },
    buttonsStyling: false
  });
  swalWithBootstrapButtons.fire({
    title: confirmText || "Are you sure about this action?",
    text: confirmTextHelper || "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, Continue!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      if (func) {
        try {
          func();
          swalWithBootstrapButtons.fire({
            title: "Completed!",
            text: successText || "This action has been completed!.",
            icon: "success"
          });
        } catch (e) {
          swalWithBootstrapButtons.fire({
            title: "Ops!",
            text: e.toString(),
            icon: "error"
          });
          if (errfunc) {errfunc()}
        }
      }  
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire({
        title: "Cancelled",
        text: cancelText || "Action aborted",
        icon: "error"
      });
      if (errfunc) {errfunc()}
    }
  });
}