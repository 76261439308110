<template>
  <!-- Header -->
  <div class="relative md:pt-4 pb-4 pt-2">
    <div class="px-4 md:px-4 mx-auto w-full" v-if="has('plan.summary.view')">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div v-for="(stat, i) in Object.values(planSummary())" :key="i" class="w-full lg:w-6/12 xl:w-3/12 p-2">
            <card-stats
              :statSubtitle="`${(stat?.status?.toLowerCase()==='retry'? 'DUE': stat.status)} ${params?.type?.toUpperCase() || 'plan'}`"
              :statTitle="formatter().format(stat.amount || 0)"
              :statArrow="`${'up'}`"
              :statCount="`${stat.total}`"
              :statPercentColor="`text-orange-500`"
              :statDescripiron="`${stat?.status?.toLowerCase()==='retry'? 'due': stat?.status?.toLowerCase()} ${params.type || 'plan'} count`"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
        </div>
      </div>

      <div class="flex mb-3 justify-end">
        <button
          class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
          type="button" 
          :disabled="loading"
          @click="toggleFilterModal"
        >
        <i class="pi pi-filter text-xs" style="font-size: 10px;"></i> Filter
        </button>
        
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
          type="button" 
          @click="downloadSummary"
          :disabled="!has('transaction.view') || loading || loaders().fetchSummary || loaders().getDashboard" 
        >
        <i class="pi pi-download text-xs" style="font-size: 10px;"></i> Download
        </button>
      </div>
      
    </div>

    <filter-modal 
      :selects="{
        type: ['loan', 'savings', 'investment'],
        category: planOptions,
        duration: ['24hrs', '1week', '30days', '3months', '6months', '1year'].map( t => ({key: t, value: `${t} Ago`})),
        status: ['INITIATED', 'PENDING', 'FAILED', 'ACTIVE', 'DECLINED', 'RETRY', 'COMPLETED', 'WITHDRAWN', 'APPROVED', 'DUE', 'DEFAULT', 'BAD']
      }"
      :dates="{
        from: 'Start Date',
        to: 'End Date'
      }"
      :searchable="true"
      :sizable="true"
      :visible="modals.filter"
      @close="modals.filter=false" 
      :title="params.type || 'plan'"
      @filter="filter"
      :loading="loading || loaders().fetchSummary || loaders().getDashboard"
    ></filter-modal>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'
import FilterModal from "@/components/Modals/FilterModal.vue";

export default {
  components: {
    CardStats,
    FilterModal
  },
  created() {
  },
  mounted() {
    this.getPlanSummary({})
  },
  data() {
    return {
      params: {},
      modals: {},
      businessOptions: [],
      planOptions: []
    }
  },
  methods: {
    ...mapActions(["getPlanSummary"]),
    ...mapGetters(["planSummary", "plans", "businesses", "userPlanDefaultStatSummary", "loaders"]),
    // Create our number formatter.
    formatter() {
      return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    },
    paramsToQuery(param = null) {
      return '?' + Object.entries(param || this.params).filter(([,v]) => v && v !== "").map(([k, v]) => `${k}=${v}`).join("&")
    },
    fetchFilteredSummary() {
      this.params = {
        ...this.params
      }
      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)});
    },
    parseDuration(duration) {
      let to = null
      let from = null
      const value = duration;
      switch (value) {
        case "24hrs":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(1, 'day').format("YYYY-MM-DD")
          break;
        case "1week":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(1, 'week').format("YYYY-MM-DD")
          break;
        case "30days":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(1, 'month').format("YYYY-MM-DD")
          break;
        case "3months":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(3, 'month').format("YYYY-MM-DD")
          break;
        case "6months":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(6, 'month').format("YYYY-MM-DD")
          break;
        case "1year":
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(1, 'year').format("YYYY-MM-DD")
          break;  
        default:
          to = moment(new Date).format("YYYY-MM-DD")
          from = moment(new Date).subtract(5, 'year').format("YYYY-MM-DD")
          break;
      }
      this.params = {...this.params, to, from}
    },
    filter(p={}) {
      if (p.duration) {
        this.parseDuration(p.duration)
      }
      this.params = {
        ...p,
        ...this.params, 
      }
      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)})
    },
    downloadSummary() {
      this.$emit('statChange', {...this.params, get_csv: true})
    },
    toggleFilterModal() {
      this.setBusinesses()
      this.setPlans()
      this.modals.filter = !this.modals.filter
    },
    setBusinesses: function() {
      this.businessOptions = this.businesses()?.data?.map( b => ({key: b.id, value: `${b.name} | ${b.code}` }))
    },
    setPlans: function() {
      this.planOptions = this.plans().filter(p => this.params.type===p.type).map(p => p.name)
    }
  }
};
</script>
