export const registerUser = (state, user) => {
    state.user = user;
};

export const fetchProfile = (state, profile) => {
    state.userProfile = profile;
    return profile;
}

export const saveProfile = (state, profile) => {
    state.userProfile = profile;
    return profile;
}

export const fetchAuth = (state, auth) => {
    state.auth = auth;
};

export const verifyUser = (state, verify) => {
    //
    console.log(state, verify)
};

export const authCode = (state, code) => {
    //
    console.log(state, code)
};

export const forgotPassword = (state, code) => {
    //
    console.log(state, code)
};

export const resetPassword = (state, code) => {
    //
    console.log(state, code)
};


// export const authUser = (state, auth) => {
//     window.localStorage.setItem("rep", auth.token)
//     window.localStorage.setItem("verified", auth.verified)
//     window.localStorage.setItem("score", auth.score)
//     window.localStorage.setItem("uuid", auth.uuid)
//     state.auth = auth;
//     return auth;
// };

export const authUser = (state, auth) => {
    const { token, verified, scores = {}, uuid } = auth;
    if ( token ) {
        window.localStorage.setItem("rep", token)
    }
    window.localStorage.setItem("verified", verified)
    window.localStorage.setItem("scores", JSON.stringify(scores || {}))
    if ( uuid ) {
        window.localStorage.setItem("uuid", uuid)
    }
        // state.auth = auth;
    return auth;
};

export const logout = (data) => {
    return logoutAuthUser(data);
}

export const logoutAuthUser = (state) => {
    window.localStorage.removeItem("rep");
    window.localStorage.removeItem("verified");
    window.localStorage.removeItem("score");
    window.localStorage.removeItem("uuid");
    state.auth = {};
};

export const getAllUsers = (state, users) => {
    state.users = users;
}

export const getNextOfKins = (state, data) => {
    state.nextOfKins = data;
}

export const saveNextOfKin = (state, nextOfKin) => {
    state.nextOfKins.data = [nextOfKin, ...state.nextOfKins.data];
};

export const updateNextOfKin = (state, nextOfKin) => {
    const nextOfKinIndex = state.nextOfKins.data.findIndex(
        (_nextOfKin) => _nextOfKin.id === nextOfKin.id
    );
    if (nextOfKinIndex < 0) {
        return;
    }
    state.nextOfKins.data[nextOfKinIndex] = nextOfKin;
};

export const deleteNextOfKin = (state, nextOfKin) => {
    const nextOfKins = state.nextOfKins.data.filter(
        (_nextOfKin) => _nextOfKin.id !== nextOfKin.id
    );
    state.nextOfKins.data = nextOfKins;
};

export const registerUserProxy = (state, user) => {
    state.users.data = [user, ...state.users.data];
}

const UserMutation = {
    verifyUser,
    logout,
    logoutAuthUser,
    registerUser,
    fetchProfile,
    saveProfile,
    authUser,
    authCode,
    resetPassword,
    forgotPassword,
    fetchAuth,
    getAllUsers,
    getNextOfKins,
    saveNextOfKin,
    updateNextOfKin,
    deleteNextOfKin,
    registerUserProxy
};

export default UserMutation;