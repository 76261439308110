<template>
  <div class="flex flex-wrap w-full">
    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col break-words bg-white w-full pb-6 mb-6 shadow-xl rounded-lg mt-16"
      >
        <div class="px-6">
          <h3
            class="text-xl font-semibold leading-normal mb-2 text-blueGray-700"
          >
            Send Us An Email
          </h3>
          <div class="mb-2 text-blueGray-600 mt-10">
            <i class="fas fa-envelope mr-2 text-lg text-blueGray-400"></i>
            <a href="mailto:admin@redge.ng">admin@redge.ng</a>
          </div>
          <div class="mb-2 text-blueGray-600">
            <i class="fas fa-envelope mr-2 text-lg text-blueGray-400"></i>
            <a href="mailto:management@redge.ng">management@redge.ng</a>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col break-words bg-white w-full pb-6 mb-6 shadow-xl rounded-lg mt-16"
      >
        <div class="px-6 w-full">
          <h3
            class="text-xl font-semibold leading-normal mb-2 text-blueGray-700"
          >
            Call Us On
          </h3>
          <p class="mb-2 text-blueGray-600 mt-10">
            <i class="fas fa-phone mr-2 text-lg text-blueGray-400"></i>
            <a href="tel:08023456789">+234 802-345-6789</a>
          </p>
          <p class="mb-2 text-blueGray-600">
            <i class="fas fa-phone mr-2 text-lg text-blueGray-400"></i>
            <a href="tel:02013309447">(0201)-330-9447</a>
          </p>
        </div>
      </div>
    </div>

    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col break-words bg-white w-full pb-6 mb-6 shadow-xl rounded-lg mt-16"
      >
        <div class="px-6">
          <h3
            class="text-xl font-semibold leading-normal mb-2 text-blueGray-700"
          >
            Chat With Us
          </h3>
          <div class="mb-2 text-blueGray-600 mt-10">
            <i class="fas fa-chat mr-2 text-lg text-blueGray-400"></i>
            chat
          </div>
        </div>
      </div>
    </div>
    
    <p class="text-sm m-4"> we are avialable 24hrs 7 days in a week.</p>
  </div>
</template>
<script>
import team2 from "@/assets/img/team-2-800x800.jpg";

export default {
  data() {
    return {
      team2,
    };
  }
};
</script>
