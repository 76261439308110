<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <!-- <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold" style="color:#065f46">API & Theme Config</h6>
        <a
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          href="https://developer.redge.ng"
        >
          Documentation
        </a>
      </div>
    </div> -->
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-lg">
          <i class="pi pi-cog"></i> API & Theme Config
        </h6>
        <div class="flex mb-3">
          <button
            class="text-white text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button"
            @click="page=pages.DNS"
            :class="{
              'bg-indigo-500': page===pages.DNS,
              'active:bg-indigo-600': page===pages.DNS,
              'bg-emerald-500': page!==pages.DNS,
              'active:bg-emerald-600': page!==pages.DNS
            }"
          >
          <i class="pi pi-server text-xs" style="font-size: 10px;"></i> DNS
          </button>
          
          <button
            class="text-white text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            @click="page=pages.THEME"
            :class="{
              'bg-indigo-500': page===pages.THEME,
              'active:bg-indigo-600': page===pages.THEME,
              'bg-emerald-500': page!==pages.THEME,
              'active:bg-emerald-600': page!==pages.THEME
            }"
          >
          <i class="pi pi-microsoft text-xs" style="font-size: 10px;"></i> Theme
          </button>

          <button
            class="text-white text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            @click="page=pages.WEB_HOOK"
            :class="{
              'bg-indigo-500': page===pages.WEB_HOOK,
              'active:bg-indigo-600': page===pages.WEB_HOOK,
              'bg-emerald-500': page!==pages.WEB_HOOK,
              'active:bg-emerald-600': page!==pages.WEB_HOOK
            }"
          >
          <i class="pi pi-link text-xs" style="font-size: 10px;"></i> Webhook
          </button>
        </div>
      </div>
    </div>

    <div class="flex-auto px-4 py-10 pt-0" v-if="page===pages.DNS">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          DNS
        </h6>
        <div class="flex flex-wrap" v-if="hasBusiness()">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Subdomain :
              </label>
              <input
                type="text"
                :disabled="loaders().addAppConfig || loaders().updateAppConfig"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150 mb-2"
                v-model="v$.app_config.subdomain.$model"
                @keyup="isSubdomainExist"
              />
              <div v-if="isCheckingSubdomain" class="text-xs text-blueGray-600">
                <i>checking...</i>
              </div>
              <div v-else-if="app_config.subdomain && app_config.subdomain.length > 2 && isSubdomainExists" class="text-xs text-red-500">
                The subdomain {{ app_config.subdomain }} is already taken
              </div>
              <div v-else-if="app_config.subdomain && app_config.subdomain.length > 2 && !isSubdomainExists" class="text-xs text-emerald-600">
                Congratulation: your dedicated website can be found in <a :href="`https://${app_config.subdomain}.app.redge.ng`" target="_blank">https://{{ app_config.subdomain }}.app.redge.ng</a>
              </div>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.app_config.subdomain.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div>
              <p 
                class="text-emerald-600 text-sm my-2 underline cursor-pointer"
                @click="toggleModal('configureDomain', true)"
              >
                - Configure a subdomain
              </p>
              <p 
                class="text-emerald-600 text-sm underline my-2 cursor-pointer"
                @click="toggleModal('configureDomain', true)"
              >
                - Configure a domain
              </p>
            </div>
          </div>
        </div>
        <div v-else class="text-sm">
          Kindly fill the business details in compliance tab <i class="fas fa-exclamation-circle text-xs text-orange-500"></i>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <div class="relative w-full mb-3 px-4 mt-3 flex justify-end">
          <button
            class="bg-emerald-500 flex text-center justify-end text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            :disabled="loaders().addAppConfig || loaders().updateAppConfig || v$.app_config.$invalid || !hasBusiness()"
            @click="persistConfig"
          >
            <span v-if="loaders().addAppConfig || loaders().updateAppConfig" class="flex loader loader-sm"></span><span v-else>Update</span>
          </button>
        </div>

      </form>
    </div>
    
    <div class="flex-auto px-4 py-10 pt-0" v-if="page===pages.THEME">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          App Theme
        </h6>
        <div class="flex flex-wrap" v-if="hasBusiness()">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                App Name :
              </label>
              <input
                type="text"
                :disabled="loaders().addAppConfig || loaders().updateAppConfig"
                class="border-0 px-2 py-2 bg-white text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.app_config.app_name.$model"  
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.app_config.app_name.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4 py-2">
            <div 
              class="text-sm cursor-pointer w-full"
              @click="toggleActiveColor('primary_color')"
            >
              <span class="text-emerald-600 my-2 underline">- Primary Color: </span> {{ app_config.primary_bg_color }}
              <i class="fa fa-pen mr-1 text-xs text-blueGray-400"></i><i class="text-blueGray-600 text-xs">edit</i>
            </div>
              
            <div 
              class="relative w-full lg:w-4/12 my-2"
              v-if="active_color === 'primary_color'"
            >
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Set Primary Color
              </label>
              <input
                type="color"
                :disabled="loaders().addAppConfig || loaders().updateAppConfig"
                class="border-0 h-8 bg-white w-full text-sm shadow focus:outline-none ease-linear transition-all duration-150"
                v-model="v$.app_config.primary_bg_color.$model"  
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.app_config.primary_bg_color.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          
          <div class="w-full lg:w-12/12 px-4 py-2">
            <div 
              class="text-sm cursor-pointer w-full"
              @click="toggleActiveColor('secondary_color')"
            >
              <span class="text-emerald-600 my-2 underline">- Secondary Color: </span> {{ app_config.secondary_bg_color }}
              <i class="fa fa-pen mr-1 text-xs text-blueGray-400"></i><i class="text-blueGray-600 text-xs">edit</i>
            </div>

            <div 
              class="relative w-full lg:w-4/12 my-2"
              v-if="active_color === 'secondary_color'"
            >
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Set Secondary Color
              </label>
              <input
                type="color"
                :disabled="loaders().addAppConfig || loaders().updateAppConfig"
                class="border-0 h-8 bg-white w-full text-sm shadow focus:outline-none ease-linear transition-all duration-150"
                v-model="v$.app_config.secondary_bg_color.$model"  
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.app_config.secondary_bg_color.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4 py-2">
            <div 
              class="text-sm cursor-pointer w-full"
              @click="toggleActiveColor('accent_color')"
            >
              <span class="text-emerald-600 my-2 underline">- Accent Color: </span> {{ app_config.accent_color }}
              <i class="fa fa-pen mr-1 text-xs text-blueGray-400"></i><i class="text-blueGray-600 text-xs">edit</i>
            </div>
            <div 
              class="relative w-full lg:w-4/12 my-2"
              v-if="active_color === 'accent_color'"
            >
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Set Accent Color
              </label>
              <input
                type="color"
                :disabled="loaders().addAppConfig || loaders().updateAppConfig"
                class="border-0 h-8 bg-white w-full text-sm shadow focus:outline-none ease-linear transition-all duration-150"
                v-model="v$.app_config.accent_color.$model"  
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.app_config.accent_color.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4 py-2">
            <div 
              class="text-sm cursor-pointer w-full"
              @click="toggleActiveColor('primary_text_color')"
            >
              <span class="text-emerald-600 my-2 underline">- Primary Text Color: </span> {{ app_config.primary_text_color }}
              <i class="fa fa-pen mr-1 text-xs text-blueGray-400"></i><i class="text-blueGray-600 text-xs">edit</i>
            </div>
            <div 
              class="relative w-full lg:w-4/12 my-2"
              v-if="active_color === 'primary_text_color'"
            >
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Set Primary Text Color
              </label>
              <input
                type="color"
                :disabled="loaders().addAppConfig || loaders().updateAppConfig"
                class="border-0 h-8 bg-white w-full text-sm shadow focus:outline-none ease-linear transition-all duration-150"
                v-model="v$.app_config.primary_text_color.$model"  
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.app_config.primary_text_color.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4 py-2">
            <div 
              class="text-sm cursor-pointer w-full"
              @click="toggleActiveColor('secondary_text_color')"
            >
              <span class="text-emerald-600 my-2 underline">- Secondary Text Color: </span> {{ app_config.secondary_text_color }}
              <i class="fa fa-pen mr-1 text-xs text-blueGray-400"></i><i class="text-blueGray-600 text-xs">edit</i>
            </div>
            <div 
              class="relative w-full lg:w-4/12 my-2"
              v-if="active_color === 'secondary_text_color'"
            >
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Set Secondary Text Color
              </label>
              <input
                type="color"
                :disabled="loaders().addAppConfig || loaders().updateAppConfig"
                class="border-0 h-8 bg-white w-full text-sm shadow focus:outline-none ease-linear transition-all duration-150"
                v-model="v$.app_config.secondary_text_color.$model"  
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.app_config.secondary_text_color.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-12/12 px-4 py-2 flex flex-wrap">
            <div class="relative w-full lg:w-4/12 mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Text Font
              </label>
              <select
                :disabled="loaders().addAppConfig || loaders().updateAppConfig"
                class="border-0 h-8 bg-white w-full text-sm shadow focus:outline-none ease-linear transition-all duration-150"
                v-model="v$.app_config.text_font.$model"  
              >
              <option v-for="(font, i) in ['Lato', 'inter', 'Roboto', 'Open Sans']" :key="i">{{ font }}</option>
              </select>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.app_config.text_font.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="relative w-full lg:w-8/12 mb-3 flex items-center">
              <p 
                class="text-sm text-blueGray-600 px-4"
                :class="`${app_config.text_font.split(' ').map( v => v.toLowerCase()).join('-')}`"
              >Here is a sample of what the font looks like</p>
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                App Logo
              </label>
              <input
                type="file"
                :disabled="loaders().addAppConfig || loaders().updateAppConfig"
                class="border-0 px-2 py-1 h-8 bg-white text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                @change="uploadLogo"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.app_config.logo.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4 flex items-center">
            <div class="relative w-full mb-3">
              <div v-if="app_config.logo" class="flex items-center">
                <img :src="app_config.logo" class="preview-image" />
              </div>
              <div v-else class="text-xs mt-2"><i>Supply a logo not more than 100kb and 700 x 250 </i></div>
              <div v-if="uploading.logo">
                <i>updatiing logo...</i>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Banner
              </label>
              <input
                type="file"
                :disabled="loaders().addAppConfig || loaders().updateAppConfig"
                class="border-0 px-2 py-1 h-8 bg-white text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                @change="uploadBanner" 
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.app_config.banner.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4 flex items-center">
            <div class="relative w-full mb-3">
              <div v-if="app_config.banner" class="flex items-center">
                <img :src="app_config.banner" class="preview-image"/>
              </div>
              <div v-else class="text-xs mt-2"><i>Supply a banner not more than 500kb and 1920 x 1280 </i></div>
              <div v-if="uploading.banner">
                <i>updatiing banner...</i>
              </div>
            </div>
          </div>

        </div>
        <div v-else class="text-sm">
          Kindly fill the business details in compliance tab <i class="fas fa-exclamation-circle text-xs text-orange-500"></i>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <div class="relative w-full mb-3 px-4 mt-3 flex justify-end">
          <button
            class="bg-emerald-500 flex text-center justify-end text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            :disabled="loaders().addAppConfig || loaders().updateAppConfig || v$.app_config.$invalid || !hasBusiness()"
            @click="persistConfig"
          >
            <span v-if="loaders().addAppConfig || loaders().updateAppConfig" class="flex loader loader-sm"></span><span v-else>Update</span>
          </button>
        </div>

      </form>
    </div>

    <div class="flex-auto px-4 py-10 pt-0" v-if="page===pages.WEB_HOOK">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Webhook
        </h6>
        <div class="flex flex-wrap" v-if="hasBusiness()">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Event Webhook :
              </label>
              <input
                type="text"
                :disabled="businesses().data && businesses().data.length === 0"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.business.webhook_url.$model"  
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.webhook_url.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-sm">
          Kindly fill the business details in compliance tab <i class="fas fa-exclamation-circle text-xs text-orange-500"></i>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <div class="relative w-full mb-3 px-4 mt-3 flex justify-end">
          <app-button
            :classes="'bg-emerald-500 flex text-center text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150'"
            type="button" 
            :disabled="loaders().saveProfile || (businesses().data && businesses().data.length === 0) || v$.business.$invalid || !hasBusiness()"
            @click="saveBusiness"
            title="Update"
            :loading="loaders().saveProfile"
          ></app-button>
        </div>

      </form>
    </div>

    <div v-if="modals.configureDomain" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Configure Domain
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('configureDomain', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <div>
              <p class="text-sm text-blueGray-600 py-4">
                Redge Subdomain: <i class="text-blueGray-600 underline"><a :href="`https://${app_config.subdomain}.app.redge.ng`">https://{{ app_config.subdomain }}.app.redge.ng</a></i>
              </p>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Enter Custom Domain: 
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150 mb-2"
                      v-model="v$.custom_domain.$model"
                    />

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.custom_domain.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border rounded-sm py-4 px-4">
              <p class="text-xs bg-blueGray-300 mb-2">
                <i class="fas fa-exclamation text-xs mr-2 text-orange-500"></i>
                For this to work, you will need to setup a {{ is_sub_domain ? 'CNAME' : 'ALIAS' }} for the subdomain listed 
                above. You can setup a {{ is_sub_domain ? 'CNAME' : 'ALIAS' }} to your site by using the following information:
                <br/>
                <span v-if="!is_sub_domain">
                  <strong>Note: </strong> Many registrar don't support ALIAS. If yours does not, 
                  you will need to use a subdomain.
                </span>
              </p>
              <table class="border-collapse border-solid border-2 mt-8">
                <thead>
                  <tr>
                    <th class="px-6 align-middle text-xs whitespace-nowrap py-2 text-left border-collapse border-solid border-2">Type</th>
                    <th class="px-6 align-middle text-xs whitespace-nowrap py-2 text-left border-collapse border-solid border-2">Host</th>
                    <th class="px-6 align-middle text-xs whitespace-nowrap py-2 text-left border-collapse border-solid border-2">Answer</th>
                    <th class="px-6 align-middle text-xs whitespace-nowrap py-2 text-left border-collapse border-solid border-2">TTL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      class="px-6 align-middle text-xs whitespace-nowrap py-2 text-left border-collapse border-solid border-2"
                    >
                    {{ is_sub_domain ? 'CNAME' : 'ALIAS' }}
                    </td> 
                    <td class="px-6 align-middle text-xs whitespace-nowrap py-2 text-left border-collapse border-solid border-2">
                      {{ custom_domain }}
                    </td>
                    <td class="px-6 align-middle text-xs whitespace-nowrap py-2 text-left border-collapse border-solid border-2">
                      https://{{ app_config.subdomain }}.app.redge.ng
                    </td>
                    <td class="px-6 align-middle text-xs whitespace-nowrap py-2 text-left border-collapse border-solid border-2">
                      300
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr/>
            <p class="text-sm text-blueGray-500 mt-8 mb-3">
              <strong>SSL CERTIFICATE: </strong>
              We will automatically generate an SSL certificate on your behalf. In order for us to do this, you'll need to set up a <br/>
              DNS CAME record pointing to our secure server. Your lending app will be available through your custom domain in a few minutes <br/>
              - no additional work needed! <br/>
              Behind the scenes, we use Cloudflare to provision an X.509 (TLS/SSL) certificate for your custom domain. Your certificate's chain <br/>
              of trust will originate with Let's Encrypt or Google Trust Services, both of which are trusted by nearly all browsers and platforms.
            </p>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('configureDomain', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.configureDomain" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { createToaster } from "@meforma/vue-toaster";
import AppButton from '@/components/Form/AppButton.vue';
import { UploadBanner, UploadLogo } from "@/services/business";
import { getImageDimensions } from "@/utils"
const toaster = createToaster({});

export default {
  props: ["profile"],
  components: {
    AppButton
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      business: {
        webhook_url  : {required},
      },
      app_config: {
        app_name              :{required},
        primary_text_color    :{}, 
        secondary_text_color  :{}, 
        primary_bg_color      :{}, 
        secondary_bg_color    :{},
        accent_color          :{}, 
        logo                  :{}, 
        banner                :{}, 
        subdomain             :{}, 
        domain                :{}, 
        is_active             :{},
        text_font             :{}
      },
      custom_domain: {required}
    }
  },
  computed: {
    is_sub_domain: function() {
      if (!this.custom_domain) {
        return false
      }
      return this.custom_domain.split(".").length > 2;
    }
  },
  data() {
    return {
      business: {},
      app_config: {},
      checkSubdomainTimer: null,
      isCheckingSubdomain: false,
      isSubdomainExists: false,
      uploading: {},
      modals: {},
      custom_domain: null,
      active_color: null,
      pages: {
        DNS: "DNS",
        THEME: "THEME",
        WEB_HOOK: "WEB_HOOK"
      },
      page: "DNS"
    }
  },
  watch: {
    '$store.state.businesses': function(n) {
      const business = n.data[0] || {}
      this.business = business;
      if (business.app_config) {
        this.app_config = business.app_config;
        if ( business.app_config.primary_bg_color ) {
          this.app_config.primary_bg_color = this.rgbToHex(business.app_config.primary_bg_color)
        }
      } else if ( Object.values(this.business).some( v => v) ) {
        this.app_config = {
          subdomain: business.code,
          app_name: business.name
        }
      }
    }
  },
  methods: {
    ...mapActions([
      "updateBusiness",
      "addAppConfig",
      "updateAppConfig",
      "checkSubdomain"
    ]),
    ...mapGetters(["loaders", "businesses"]),
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    saveBusiness() {
      const businesses = this.businesses().data;
      if ( businesses.length === 0 ) {
        toaster.info("Please fill the compliance details first")
        return;
      }
      const data = {
        ...this.business,
        ...businesses[0]
      }
      this.updateBusiness(data).then(() => {})
    },
    persistConfig() {
      const data = this.app_config;
      if ( data.primary_bg_color ) {
        data.primary_bg_color = this.hexToRgb(data.primary_bg_color).join(', ')
      }
      const query = data.id ? this.updateAppConfig(data) : this.addAppConfig({...data, business_id: this.business.id});
      query.then(() => {});
    },
    isSubdomainExist(e) {
      const subdomain = e.target.value;
      if (e.length < 2 ) {
        return
      }
      if ( this.checkSubdomainTimer !== null ) {
        clearTimeout(this.checkSubdomainTimer);
      }
      this.isCheckingSubdomain = true;
      const self = this
      this.checkSubdomainTimer = setTimeout(() => {
        self.checkSubdomain({
          identifier: subdomain,
          identifiertype: "subdomain"
        }).then( res => {
          self.isSubdomainExists = res
        }).finally(() => {
          self.isCheckingSubdomain = false
        })
      }, 2000);
    },
    hasBusiness: function() {
      return this.business && Object.values(this.business).some( v => v)
    },
    hexToRgb: c => c.match(/\w\w/g).map(x=>+`0x${x}`),
    rgbToHex: c => '#'+c.match(/\d+/g).map(x=>(+x).toString(16).padStart(2,0)).join``,
    async uploadLogo(e) {
      const file = e.target.files[0];
      const { width, height } = await getImageDimensions(file)
      if ( width > 700 || height > 300 ) {
        toaster.error("Upload a logo of size not more than 700 x 250")
        return
      }
      if ( file.size/1024 > 100 ) {
        toaster.error("Upload a logo of size not more than 100kb")
        return
      }
      if( !this.business.id ) {
        toaster.error("no business info found")
        return 
      }
      const formData = new FormData();
      formData.append("file", file);
      this.uploading.logo = true;
      const res = await UploadLogo(formData, this.business.id);
      this.uploading.logo = false;
      this.file = res.data.data;
      this.app_config.logo = res.data.data;
    },
    async uploadBanner(e) {
      const file = e.target.files[0];
      const { width, height } = await getImageDimensions(file)
      if ( width > 1920 || height > 1280 ) {
        toaster.error("Upload a logo of size not more than 1920 x 1280")
        return
      }
      if ( file.size/1024 > 500 ) {
        toaster.error("Upload a logo of size not more than 500kb")
        return
      }
      if( !this.business.id ) {
        toaster.error("no business info found")
        return 
      } 
      const formData = new FormData();
      formData.append("file", file);
      this.uploading.banner = true;
      const res = await UploadBanner(formData, this.business.id);
      this.uploading.banner = false;
      this.file = res.data.data;
      this.app_config.banner = res.data.data;
    },

    toggleActiveColor: function(color) {
      this.active_color = this.active_color && this.active_color === color ? null : color
    }
  }
};
</script>

<style scoped>
.preview-image {
  width: 40px;
}
.roboto {
  font-family: "roboto", sans-serif;
}
.lato {
  font-family: "lato", sans-serif;
}
.open-sans {
  font-family: "Open Sans", sans-serif;
}
.inter {
  font-family: "inter", sans-serif;
}
</style>