<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Plan</h6>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button" 
          @click="plan = {}"
          v-if="has('plans.store')"
        >
          Plan
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          <i class="fas fa-exclamation mr-1 text-sm text-emerald-600"></i> Plan Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                name
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.name.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.name.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Duration 
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.duration.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.duration.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Duration Unit
              </label>
              <select v-model="v$.plan.duration_unit.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for="(type, i) in ['month', 'hour', 'day', 'week', 'year']" :key="i" :selected="type === plan.duration_unit">{{ type }}</option>
              </select>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.duration_unit.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Wait duration
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.wait.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.wait.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Wait Unit
              </label>
              <select v-model="v$.plan.wait_unit.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for="(type, i) in ['month', 'hour', 'day', 'week', 'year']" :key="i" :selected="type === plan.wait_unit">{{ type }}</option>
              </select>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.wait_unit.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Type
              </label>
              <select v-model="v$.plan.type.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for="(type, i) in ['savings', 'loan', 'estate', 'investment']" :key="i" :selected="type === plan.type">{{ type }}</option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.type.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              reference
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.reference.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.reference.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          <i class="fas fa-cogs mr-1 text-sm text-emerald-600"></i> Settings
        </h6>
      
        <div class="flex flex-wrap text-emerald-600">
          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              opt out penalty (%)
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.opt_out_penalty.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.opt_out_penalty.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full px-4 py-1">
            <div class="relative w-full mb-3 text-xs">       
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Visibility
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.plan.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                <span class="ml-2 text-xs text-emerald-600 underline italic mr-2"> Make Active? </span>
                <i v-if="plan.is_active" class="fas fa-eye mr-1 text-sm text-emerald-600"></i>
                <i v-else class="fas fa-eye-slash mr-1 text-sm text-emerald-600"></i>  
              </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.is_active.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full px-4 py-1">
            <div class="relative w-full mb-3">
              
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              flexibilty
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.plan.is_flexible.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                  <span class="ml-2 text-xs mr-2 text-emerald-600 underline italic"> Can Opt Out? </span>
                  <i v-if="plan.is_flexible" class="fas fa-square mr-1 text-sm text-emerald-600"></i>
                  <i v-else class="fas fa-play mr-1 text-sm text-emerald-600"></i>
                </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.is_flexible.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap w-full py-1">
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Group Plan
                </label>
                <label class="inline-flex items-center cursor-pointer">
                  <input id="opt-out" type="checkbox" v-model="v$.plan.is_group.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                    <span class="ml-2 text-xs mr-2 text-emerald-600 underline italic"> Make Group Plan? </span>
                    <i class="fas fa-object-group mr-1 text-sm text-emerald-600"></i>  
                  </label>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.is_group.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4"  v-if="plan.is_group">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Strategy
                </label>
                <select v-model="v$.plan.strategy.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                  <option v-for="(strategy, i) in ['simultaneous', 'linear']" :key="i" :selected="strategy === plan.strategy">{{ strategy }}</option>
                </select>

                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.strategy.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap w-full py-1">
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Balance Requirement
                </label>
                <label class="inline-flex items-center cursor-pointer">
                  <input id="opt-out" type="checkbox" v-model="v$.balance_requirement.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                    <span class="ml-2 text-xs mr-2 text-emerald-600 underline italic"> Requires Balance? </span>
                    <i class="fas fa-credit-card mr-1 text-sm text-emerald-600"></i> 
                  </label>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.balance_requirement.is_active.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4 flex" v-if="balance_requirement.is_active">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Wallet Balance
                </label>
                <input
                  type="number"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.balance_requirement.settings.balance_reserve.$model"
                />

                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.balance_requirement.settings.balance_reserve.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4 flex" v-if="balance_requirement.is_active">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Wallet Balance Unit
                </label>
                <select v-model="v$.balance_requirement.settings.balance_reserve_unit.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                  <option v-for='(cur, i) in ["FLAT", "PERCENTAGE"]' :key="i" :selected="cur === balance_requirement.settings.balance_reserve_unit">{{ cur }}</option>
                </select>

                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.balance_requirement.settings.balance_reserve_unit.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap w-full py-1">
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Savings Requirement
                </label>
                <label class="inline-flex items-center cursor-pointer">
                  <input id="opt-out" type="checkbox" v-model="v$.savings_requirement.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                    <span class="ml-2 text-xs mr-2 text-emerald-600 underline italic"> Requires Savings? </span>
                    <i class="fas fa-folder mr-1 text-sm text-emerald-600"></i> 
                  </label>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.savings_requirement.is_active.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4 flex" v-if="savings_requirement.is_active">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Savings Balance
                </label>
                <input
                  type="number"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.savings_requirement.settings.savings_reserve.$model"
                />

                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.savings_requirement.settings.savings_reserve.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4 flex" v-if="savings_requirement.is_active">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Savings Balance Unit
                </label>
                <select v-model="v$.savings_requirement.settings.savings_reserve_unit.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                  <option v-for='(cur, i) in ["FLAT", "PERCENTAGE"]' :key="i" :selected="cur === savings_requirement.settings.savings_reserve_unit">{{ cur }}</option>
                </select>

                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.savings_requirement.settings.savings_reserve_unit.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap w-full py-1">
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Guarantor Requirement
                </label>
                <label class="inline-flex items-center cursor-pointer">
                  <input id="opt-out" type="checkbox" v-model="v$.guarantor.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                    <span class="ml-2 text-xs mr-2 text-emerald-600 underline italic"> Requires Guarantor? </span>
                    <i class="fas fa-users mr-1 text-sm text-emerald-600"></i> 
                  </label>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.guarantor.is_active.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4 flex" v-if="guarantor.is_active">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  How many?
                </label>
                <input
                  type="number"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.guarantor.settings.count.$model"
                />

                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.guarantor.settings.count.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4 flex" v-if="guarantor.is_active">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Combine Balance (%)
                </label>
                <input
                  type="number"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.guarantor.settings.combine_amount.$model"
                />

                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.guarantor.settings.combine_amount.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          <i class="fas fa-user mr-1 text-sm text-emerald-600"></i> Costing
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full lg:w-6/12 mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Currency
              </label>
              <select v-model="v$.plan.base_currency.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for='(cur, i) in ["NGN", "USDT", "USDC", "USD"]' :key="i" :selected="cur === plan.base_currency">{{ cur }}</option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.base_currency.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Min Amount
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.min_amount.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.min_amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Max Amount
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.max_amount.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.max_amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- <hr/>

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Limits
        </h6> -->
        <div class="flex flex-wrap">
         
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                {{ plan.type && ['crypto', 'stock', 'forex'].includes(plan.type) ? "Stop Loss" : "Required Point" }}
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.stop_loss.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.stop_loss.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                {{ plan.type && ['crypto', 'stock', 'forex'].includes(plan.type) ? "Take Profit" : "Interest" }}
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.take_profit.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.take_profit.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ plan.type && ['crypto', 'stock', 'forex'].includes(plan.type) ? "Trail Take Profit" : "Late fee(%)" }}
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.trail_take_profit.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.trail_take_profit.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Request Fees
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.plan.request_fee.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.request_fee.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
         Details
        </h6>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Tags <span class="lowercase text-xs text-gray-500">* separated by comma (,)</span>
            </label>
            <input
              type="text"
              id="risk-type"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              :value="plan.risk_type ? JSON.parse(plan.risk_type).join(','): ''"
              v-bind:input="v$.plan.risk_type.$model"
            />
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Description
              </label>
              <textarea
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                rows="4"
                v-model="v$.plan.description.$model"
              ></textarea
              >
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.description.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                How to buy
              </label>
              <textarea
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                rows="4"
                v-model="v$.plan.how_to_buy.$model"
              ></textarea
              >
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.how_to_buy.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          <i class="fas fa-book mr-1 text-sm text-emerald-600"></i> KYC Requirements
        </h6>
      
        <div class="flex flex-wrap text-emerald-600">
          <div class="w-full px-4 py-1">
            <div class="relative w-full mb-3 text-xs">       
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Finance
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.finance.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                <span class="ml-2 text-xs text-emerald-600 underline italic"> require bank statement? </span>
              </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.finance.is_active.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full px-4 py-1">
            <div class="relative w-full mb-3">
              
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Location
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.location.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                  <span class="ml-2 text-xs mr-2 text-emerald-600 underline italic"> eg. electricity bill, waste disposal bill, rent receipt </span>
                  <i class="fas fa-pin mr-1 text-sm text-emerald-600"></i>
                </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.location.is_active.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap w-full py-1">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Identification
                </label>
                <label class="inline-flex items-center cursor-pointer">
                  <input id="opt-out" type="checkbox" v-model="v$.identification.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                    <span class="ml-2 text-xs mr-2 text-emerald-600 underline italic"> eg. International passport, drivers license, voter’s card, national identity card </span>
                    <i class="fas fa-object-group mr-1 text-sm text-emerald-600"></i>  
                  </label>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.identification.is_active.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap w-full py-1">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Employment
                </label>
                <label class="inline-flex items-center cursor-pointer">
                  <input id="opt-out" type="checkbox" v-model="v$.employment.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                    <span class="ml-2 text-xs mr-2 text-emerald-600 underline italic"> company id card, letter of employment, payslip </span>
                    <i class="fas fa-credit-card mr-1 text-sm text-emerald-600"></i> 
                  </label>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.employment.is_active.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4 py-1">
            <div class="relative w-full mb-3">
              
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Business Document
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.business_document.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                  <span class="ml-2 text-xs mr-2 text-emerald-600 underline italic"> CAC document </span>
                  <i class="fas fa-folder mr-1 text-sm text-emerald-600"></i> 
                </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business_document.is_active.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4 flex" v-if="business_document.is_active">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Select Document
              </label>
              <select multiple v-model="v$.business_document.settings.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for='(cur, i) in ["CAC1", "CAC2", "CAC7", "certificate of incorporation"]' :key="i" :selected="cur === business_document.settings">{{ cur }}</option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business_document.settings.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Coin Type
        </h6>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <ul>
              <li v-for="(type, i) in plan.coin_types" :key="i" class="w-full flex justify-between">
                <span>{{ type.name }} <small>({{ type.symbol }})</small> - {{ type.pivot.quantity }}% </span>
                <small @click="detachCoin(type)" class="cursor-pointer hover:text-red-900">
                  x
                  remove
                </small>
              </li>
            </ul>
          </div>
        </div>

        <!-- {{ coinType() }} -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Coin Type
              </label>
              <select v-model="v$.coin_type.coin_type.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for='(coin_type, i) in coinType()' :key="i" :value="coin_type">
                {{ coin_type.name }} <small>({{ coin_type.symbol }})</small>
                </option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.coin_type.coin_type.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>

            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Quantity (%)
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.coin_type.quantity.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.coin_type.quantity.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>


          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                .
              </label>
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="attachCoin" 
                :disabled="loaders().attachCoinType "
              >
              <!-- || v$.coin_type.$invalid -->
                Add Coin Type
              </button>
            </div>
          </div>

        </div>

        <div class="relative w-full mb-3 px-4 mt-8" v-if="has('plans.store')">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="updateExistingPlan" 
            :disabled="loaders().updatePlan "
          >
            Save
          </button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, decimal, numeric, minLength, maxLength } from '@vuelidate/validators';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: [ "selectedPlan", "deletedPlan" ],
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    // whenever question changes, this function will run
    selectedPlan(plan) {
      // console.log(oldPlan, newPlan)
      this.plan = plan;
      this.plan.check_balance = !!plan.check_balance;
      this.plan.check_savings = !!plan.check_savings;
      this.plan.is_active = !!plan.is_active;
      this.plan.is_flexible = !!plan.is_flexible;
      this.plan.is_group = !!plan.is_group;
      this.plan.balance_reserve = `${plan.balance_reserve ? plan.balance_reserve : 0}`
      this.plan.savings_reserve = `${plan.savings_reserve ? plan.savings_reserve : 0}`
      this.updateConfigs(this.plan.configs);
    }
  },
  data() {
    return {
      plan: {},
      coin_type: {
        quantity: 0
      },
      //plan_id, type, settings, order, is_active, is_required, continue_on_failure, pre_plan
      guarantor: {
        id: null,
        type: "guarantor",
        is_active: false,
        pre_plan: true,
        settings: {
          count: 0,
          combine_amount: 0
        }
      },
      balance_requirement: {
        id: null,
        type: "balance_requirement",
        is_active: false,
        pre_plan: true,
        settings: {
          balance_reserve: 0,
          balance_reserve_unit: "PERCENTAGE"
        }
      },
      savings_requirement: {
        id: null,
        type: "savings_requirement",
        is_active: false,
        pre_plan: true,
        settings: {
          savings_reserve: 0,
          savings_reserve_unit: "PERCENTAGE"
        }
      },
      finance: {
        id: null,
        type: "finance",
        is_active: false,
        pre_plan: true,
        settings: {}
      },
      location: {
        id: null,
        type: "location",
        is_active: false,
        pre_plan: true,
        settings: {}
      },
      identification: {
        id: null,
        type: "identification",
        is_active: false,
        pre_plan: true,
        settings: {}
      },
      employment: {
        id: null,
        type: "employment",
        is_active: false,
        pre_plan: true,
        settings: {}
      },
      business_document: {
        id: null,
        type: "business_document",
        is_active: false,
        pre_plan: true,
        settings: []
      }
    }
  },
  mounted() {
    this.getCoinType()
  },
  validations() {
    return {
      plan: {
        name: { required },
        base_currency: { required },
        type: { required },
        description: { required },
        min_amount: { required, decimal, min: minLength(1) },
        max_amount: { required, decimal, min: minLength(1) },
        take_profit: { required },
        trail_take_profit: { required },
        stop_loss: { required, numeric },
        risk_type: { required, numeric },
        is_active: { required },
        is_group: {},
        duration: { required, numeric, min: minLength(1) },
        duration_unit: { required },
        wait: { numeric, min: minLength(1) },
        wait_unit: { },
        how_to_buy: { },
        reference: { },
        is_flexible: { },
        opt_out_penalty: { numeric },
        check_balance: {},
        balance_reserve_unit: {},
        balance_reserve: {numeric},
        check_savings: {},
        savings_reserve: {numeric},
        savings_reserve_unit: {},
        strategy: {},
        request_fee: { numeric }
      },
      coin_type: {
        coin_type: { required },
        quantity: { required, min: minLength(1), max: maxLength(2), numeric },
        plan_id: { required }
      },
      guarantor: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {
          count: { required },
          combine_amount: { required }
        }
      },
      balance_requirement: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {
          balance_reserve: { required },
          balance_reserve_unit: { required }
        }
      },
      savings_requirement: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {
          savings_reserve: { required },
          savings_reserve_unit: { required }
        }
      },
      finance: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {}
      },
      location: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {}
      },
      identification: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {}
      },
      employment: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {}
      },
      business_document: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: []
      }
    }
  },
  methods: {
    ...mapGetters(["coinType", "loaders"]),
    ...mapActions([
      "getCoinType", 
      "attachCoinType", 
      "detachCoinType", 
      "planConfigBulk",
      "updatePlan", 
      "addPlan",
    ]),
    ...mapMutations(["updatePlanCoinType", "removePlanCoinType"]),
    attachCoin() {
      const data = {
        coin_type_id: this.coin_type.coin_type.id,
        quantity: this.coin_type.quantity
      };
      if ( !this.plan.id ) {
        toaster.show('please select a plan first');
        return false;
      }
      data.plan_id = this.plan.id;
      const self = this;
      this.attachCoinType(data).then( res => {
        console.log(res);
        const newCoinType = {
          planId: self.plan.id,
          id: self.coin_type.coin_type.id,
          name: self.coin_type.coin_type.name,
          symbol: self.coin_type.coin_type.symbol,
          pivot: {
            quantity: data.quantity
          }
        };

        this.updatePlanCoinType(newCoinType)
      });
    },
    detachCoin(coinType) {
      if (!confirm("confirm to delete")) {
        toaster.show("you cancelled the operation");
      }
      const data = {
        coin_type_id: coinType.id,
        quantity: this.coin_type.quantity
      };
      if ( !this.plan.id ) {
        toaster.show('please select a plan first');
        return false;
      }
      data.plan_id = this.plan.id;
      const self = this;
      this.detachCoinType(data).then( res => {
        console.log(res)
        const newCoinType = {
          planId: self.plan.id,
          coinTypeId: coinType.id,
        };

        this.removePlanCoinType(newCoinType)
      });
    },
    updateConfigs(configs) {
      for(const config of configs) {
        config.is_active = !!config.is_active;
        config.is_required = !!config.is_required;
        config.continue_on_failure = !!config.continue_on_failure;
        config.pre_plan = !!config.pre_plan;
        config.settings = typeof config.settings === "string" ? JSON.parse(config.settings) : config.settings
        this[config.type] = config
      }
    },
    updateExistingPlan() {
      const data = this.plan;
      const risk_type = document.querySelector("#risk-type").value;
      data.risk_type = JSON.stringify(risk_type.split(","));
      data.id ? 
      this.updatePlan(data).then( () => {
        this.persistConfigs(data)
      }) : 
      this.addPlan(data).then( () => {
        this.plan = {}
        this.persistConfigs(data)
      })
      
    },
    persistConfigs(plan) {
      let configs = [
        this.guarantor,
        this.balance_requirement,
        this.savings_requirement,
        this.finance,
        this.location,
        this.identification,
        this.employment,
        this.business_document
      ];
      configs = configs.map(v => ({...v, plan_id: plan.id}))
      const self = this
      this.planConfigBulk(configs).then(r => self.updateConfigs(r))
    }
  }
}
</script>