import { GetTenant } from "@/services/app"

export const fetchTenant = (context) => {
    context.commit("updateLoader", { fetchTenant: true });
    return new Promise((resolve, reject) => {
        GetTenant()
            .then(res => {
                const data = res.data.data;
                context.commit("fetchTenant", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchTenant: false });
            });
    });
};

const AppAction = {
    fetchTenant
};

export default AppAction;