import {
    AuthUser,
    GetAuthUser,
    RegisterUser,
    Logout,
    Verify,
    AuthCode,
    ForgotPassword,
    ResetPassword,
    ValidateCode,
    ChangePassword,
    GetProfile,
    SaveProfile,
    GetNextOfKin,
    SaveNextOfKin,
    UpdateNextOfKin,
    DeleteNextOfKin,
    RegisterUserInit,
    RegisterUserProxy,
    SearchUsers,
    AuthUserWithOTP
} from "@/services/user";
import { BlockUser, GetAllUsers, UnBlockUser } from "../../services/user";

export const getProfile = (context) => {
    context.commit("updateLoader", { fetchProfile: true });
    return new Promise((resolve, reject) => {
        GetProfile()
            .then((res) => {
                context.commit("fetchProfile", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchProfile: false });
            });
    });
};

export const saveProfile = (context, profile) => {
    context.commit("updateLoader", { saveProfile: true });
    return new Promise((resolve, reject) => {
        SaveProfile(profile)
            .then((res) => {
                context.commit("saveProfile", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { saveProfile: false });
            });
    });
};

export const getAuthUser = (context) => {
    if (Object.values(context.state.auth).length > 0) {
        return;
    }
    context.commit("updateLoader", { fetchAuth: true });
    return new Promise((resolve, reject) => {
        GetAuthUser()
            .then(res => {
                context.commit("fetchAuth", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchAuth: false });
            });
        });
};

export const authUser = async(context, data) => {
    context.commit("updateLoader", { authUser: true });
    return new Promise((resolve, reject) => {
        AuthUser(data)
            .then(res => {
                const data = res.data.data;
                if (data.profile) {
                    context.commit("fetchProfile", data.profile)
                }
                context.commit("authUser", data);
                resolve(data);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { authUser: false });
            });
    });
};


export const logout = (context) => {
    // console.log(context.state.auth)
    // if (Object.values(context.state.auth).length > 0) {
    //     return;
    // }
    context.commit("updateLoader", { logout: true });
    context.commit("logout", {});
    return new Promise((resolve, reject) => {
        Logout()
            .then(res => {
                context.commit("logout", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { logout: false });
            });
    });
};


export const registerUser = (context, data) => {
    context.commit("updateLoader", { registerUser: true });

    return new Promise((resolve, reject) => {
        RegisterUser(data)
            .then(res => {
                const data = res.data.data;
                if (data.token) {
                    context.commit("authUser", data);
                } else {
                    context.commit("registerUser", data);
                }
                resolve(data);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { registerUser: false });
            });
    });
};

export const verifyUser = (context, data) => {
    context.commit("updateLoader", { verifyUser: true });
    return new Promise((resolve, reject) => {
        Verify(data)
            .then(res => {
                context.commit("verifyUser", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { verifyUser: false });
            });
    });
};

export const authCode = (context, data) => {
    context.commit("updateLoader", { authCode: true });
    return new Promise((resolve, reject) => {
        AuthCode(data)
            .then(res => {
                context.commit("authCode", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { authCode: false });
            });
    });
};

export const validateCode = (context, data) => {
    context.commit("updateLoader", { authCode: true });
    return new Promise((resolve, reject) => {
        ValidateCode(data)
            .then(res => {
                context.commit("authCode", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { authCode: false });
            });
    })
};

export const forgotPassword = (context, data) => {
    context.commit("updateLoader", { forgotPassword: true });

    return new Promise((resolve, reject) => {
        ForgotPassword(data)
            .then(res => {
                context.commit("forgotPassword", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { forgotPassword: false });
            });
    });

};

export const resetPassword = (context, data) => {
    context.commit("updateLoader", { resetPassword: true });
    return new Promise((resolve, reject) => {
        ResetPassword(data)
            .then(res => {
                context.commit("resetPassword", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { resetPassword: false });
            });
    });
};

export const changePassword = (context, data) => {
    context.commit("updateLoader", { resetPassword: true });
    return new Promise((resolve, reject) => {
        ChangePassword(data)
            .then(res => {
                context.commit("resetPassword", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { resetPassword: false });
            });
    });
};


export const getAllUsers = async(context, data) => {
    context.commit("updateLoader", { getAllUsers: true });
    return new Promise((resolve, reject) => {
        // let queryString = typeof data === "object" ? Object.entries(data).map(([k,v]) => `${k}=${v}`).join("&"): data
        GetAllUsers(data)
            .then(res => {
                if (data && data.get_csv) {
                    resolve(res.data);
                } else {
                    context.commit("getAllUsers", res.data.data);
                    resolve(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getAllUsers: false });
            });
    });
};

export const blockUser = (context, user) => {
    context.commit("updateLoader", { blockUser: true });
    return new Promise((resolve, reject) => {
        BlockUser(user)
            .then((res) => {
                // context.commit("blockUser", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { blockUser: false });
            });
    });
};

export const unBlockUser = (context, user) => {
    context.commit("updateLoader", { unBlockUser: true });
    return new Promise((resolve, reject) => {
        UnBlockUser(user)
            .then((res) => {
                // context.commit("unBlockUser", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { unBlockUser: false });
            });
    });
};

export const getNextOfKins = (context) => {
    context.commit("updateLoader", { getNextOfKins: true });
    return new Promise((resolve, reject) => {
        GetNextOfKin()
            .then((res) => {
                context.commit("getNextOfKins", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getNextOfKins: false });
            });
    });
};

export const saveNextOfKin = (context, data) => {
    context.commit("updateLoader", { saveNextOfKin: true });
    return new Promise((resolve, reject) => {
        SaveNextOfKin(data)
            .then((res) => {
                context.commit("saveNextOfKin", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { saveNextOfKin: false });
            });
    });
};

export const updateNextOfKin = (context, data) => {
    context.commit("updateLoader", { updateNextOfKin: true });
    const id = data.id;
    delete data.id;
    return new Promise((resolve, reject) => {
        UpdateNextOfKin(id, data)
            .then((res) => {
                context.commit("updateNextOfKin", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateNextOfKin: false });
            });
    });
};

export const deleteNextOfKin = (context, data) => {
    context.commit("updateLoader", { deleteNextOfKin: true });
    return new Promise((resolve, reject) => {
        DeleteNextOfKin(data)
            .then((res) => {
                context.commit("deleteNextOfKin", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteNextOfKin: false });
            });
    });
};

export const registerUserInit = (context, data) => {
    context.commit("updateLoader", { registerUserInit: true });

    return new Promise((resolve, reject) => {
        RegisterUserInit(data)
            .then(res => {
                const data = res.data.data;
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { registerUserInit: false });
            });
    });
};

export const registerUserProxy = (context, data) => {
    context.commit("updateLoader", { registerUserProxy: true });

    return new Promise((resolve, reject) => {
        RegisterUserProxy(data)
            .then(res => {
                const data = res.data.data;
                context.commit("registerUserProxy", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { registerUserProxy: false });
            });
    });
};

export const searchUsers = (context, data) => {
    context.commit("updateLoader", { searchUsers: true });

    return new Promise((resolve, reject) => {
        SearchUsers(data)
            .then(res => {
                const data = res.data.data;
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { searchUsers: false });
            });
    });
};

export const authUserWithOTP = async(context, data) => {
    context.commit("updateLoader", { authUser: true });
    return new Promise((resolve, reject) => {
        AuthUserWithOTP(data)
            .then(res => {
                const data = res.data.data;
                // console.log(data)
                if (data.profile) {
                    context.commit("fetchProfile", data.profile)
                }
                context.commit("authUser", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { authUser: false });
            });
    });
};

const UserAction = {
    getAuthUser,
    authUser,
    registerUser,
    verifyUser,
    validateCode,
    getProfile,
    saveProfile,
    authCode,
    forgotPassword,
    resetPassword,
    changePassword,
    logout,
    getAllUsers,
    unBlockUser,
    blockUser,
    getNextOfKins,
    saveNextOfKin,
    updateNextOfKin,
    deleteNextOfKin,
    registerUserInit,
    registerUserProxy,
    searchUsers,
    authUserWithOTP
};

export default UserAction;