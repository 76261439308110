export const ACTION_TYPE = {
    Add: "Add",
    Edit: "Edit"
}

export const ENVIRONMENT = process.env.NODE_ENV
export const BASE_URL = ENVIRONMENT === "production" ? "https://api.redge.ng/" : "http://jawo.local/";
export const ASTRO_PAY_APP_ID = "PbXuYJFEKLNMleTXz1jRkADgYFisgCMnr7k8LSsJVA7Am9giVkixBNHxoo16azxA"
export const PUSHER_APP_KEY = "90861b8f263129ad9082"
export const PUSHER_APP_CLUSTER = 'eu'
export const WS_HOST = ENVIRONMENT === "production" ? 'socket.redge.ng' : 'localhost';
export const WS_PORT = ENVIRONMENT === "production" ? "" : 6001
    // export const WS_HOST = 'api.redge.ng';
    // export const WS_PORT = 6001

export const transactionList = [
    "",
    "plan-subscription", 
    "alert-subscription", 
    "loan-subscription",
    "loan-request-fee",
    "loans-payment",
    "savings-payment",
    "airtime-topup",
    "data-topup",
    "cable-tv",
    "power",
    "refund", 
    "funding",
    "start-funding", 
    "withdraw",
    "commission",
    "address-verification",
    "stamp-duty",
]

export const commissionList = [
    "",
    "savings",
    "loan"
]

export const statusList = [
    '', 
    'INITIATED',
    'PENDING', 
    'FAILED', 
    'ACTIVE', 
    'DECLINED', 
    'RETRY', 
    'COMPLETED', 
    'WITHDRAWN', 
    'APPROVED', 
    'DUE', 
    'REVERSED', 
    'DEFAULT'
]