import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
export const snakeToCamelCase = (str) =>
  str.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
  );

  export const snakeToTitleCase = (str) => {
    if(!str || typeof str !== "string") return "";
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace("-", " ")
        .replace("_", " ")
    );
  }
  
  export const getInitials = (name) => {
    let names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  export const clickToCopy = (text) => {
    navigator.clipboard.writeText(text).then(function() {
      toaster.success('Copied to clipboard!');
    }, function(err) {
      toaster.show('Could not copy text: ', err);
    });
  }
  