export const formatter = (currency) => {
    const currencyCode = {
      NGN: 'en-NG',
      USD: 'en-US',
      GBP: 'en-US'
    }
    
    const code = Object.keys(currencyCode).includes(currency) ? currencyCode[currency] || currencyCode.NGN : 'en-US'
    
    return new Intl.NumberFormat(code, {
      style: 'currency',
      currency,

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
}

const cryptoSymbols = {
  "ETH": "Ξ",
  "BTC": "₿",
  "USDT": "₮",
  "USD": "$",
  "NGN": "₦",
  "NAIRA": "₦",
  "GBP": "£"
}

export const formatCurrency = ({amount, currency, abbr = false}) => {
  const num = abbr ? abbrNumber(amount) : Number(amount).toFixed(2);
  return `${cryptoSymbols[currency.toUpperCase()]}${num}`
}

export const formatAbbrCurrency = ({amount, currency}) => {
  return formatCurrency({amount, currency, abbr: true});
}

export const abbrNumber = number => {
  if (number > 1000000000000) {
    return `${Number(number/1000000000000).toFixed(2)}T`;
  } else if (number > 1000000000) {
    return `${Number(number/1000000000).toFixed(2)}B`;
  } else if (number > 1000000) {
    return `${Number(number/1000000).toFixed(2)}M`;
  } else if (number > 1200) {
    return `${Number(number/1000).toFixed(2)}k`;
  } else {
    return Number(number).toFixed(2)
  }
}