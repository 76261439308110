<template>
  <!-- Header -->
  <div class="relative bg-blueGray-100 text-emerald-800 pb-4">
    <div class="mx-auto w-full">
      <div>
        <!-- {{ summary() }} -->
        <!-- Card stats -->
        
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="MY NAIRA BALANCE"
              :statTitle="account && account.wallet && account.wallet.NGN ? formatAbbrCurrency({
                currency: 'NGN', 
                amount: account.wallet.NGN.balance
                }) + '/' + formatAbbrCurrency({
                currency: 'NGN', 
                amount: account.wallet.NGN.ledger_balance
                }) : 0"
              :statArrow="`${'up'}`"
              :statPercent="100"
              :statPercentColor="`'text-emerald-500'`"
              statDescripiron="Balance/Ledger"
              statIconName="far fa-chart-bar"
              statIconColor="bg-emerald-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="MY USD BALANCE"
              :statTitle="account && account.wallet && account.wallet.USD ? formatAbbrCurrency({
                currency: 'NGN', 
                amount: account.wallet.USD.balance
              }) + '/' + formatAbbrCurrency({
                currency: 'NGN', 
                amount: account.wallet.USD.ledger_balance
              }) : 0"
              :statArrow="`${'up'}`"
              :statPercent="100"
              :statPercentColor="`'text-emerald-500'`"
              statDescripiron="Balance/Ledger"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import { 
  // mapActions, 
  mapGetters 
} from 'vuex';
import { formatAbbrCurrency } from "../../utils/formatter.util"

export default {
  name: 'AdminBalanceStats',
  components: {
    CardStats,
  },
  computed: {
    ...mapGetters([
      "account",
    ])
  },
  // mounted() {
  //   this.getSummary({})
  // },
  created() {
    this.formatAbbrCurrency = formatAbbrCurrency
  },
  methods: {
    // ...mapActions(["getSummary"]),
    ...mapGetters(["dashboard"]),
    // Create our number formatter.
    formatter() {
      return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    }
  }
};
</script>
