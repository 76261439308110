<template>
  <div>
    <admin-balance-stats />
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
          <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
            <li class="-mb-px mr-2 last:mr-0">
              <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-orange-500': openTab === 1}">
                <i class="pi pi-chart-line" style="font-size: 10px;"></i> Wallet Transactions
              </a>
            </li>
            <li class="-mb-px mr-2 last:mr-0">
              <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-orange-500': openTab === 2}">
                <i class="pi pi-database" style="font-size: 10px;"></i> Commissions
              </a>
            </li>
          </ul>
        </div>
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div class="px-4 py-5 flex-auto">
            <div class="tab-content tab-space">
              <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}" class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                  <card-transaction :isPersonal="true"/>
                </div>
              </div>

              <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}" class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                  <card-commission />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import CardTransaction from "@/components/Cards/CardTransaction.vue";
import CardCommission from "@/components/Cards/CardCommission.vue";
import AdminBalanceStats from "@/components/Stats/AdminBalanceStats.vue";

export default {
  components: {
    CardTransaction,
    CardCommission,
    AdminBalanceStats
  },
  data() {
    return {
      openTab: 1
    }
  },
  methods: {
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    },
    selectActiveBusiness: function(activeBusiness) {
      this.activeBusiness = activeBusiness
    }
  }
};
</script>
